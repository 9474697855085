<template>
  <Main>
    <sdCards headless>
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <h2>{{$t('views.registration.products')}}</h2>
          <table-style-wrapper>
            <table-wrapper class="table-responsive">
              <a-form
                ref="formRef"
                name="sDash_validation-form"
                layout="vertical"
                :model="items"
                :validateOnRuleChange="false"
              >
                <a-table
                  :columns="columns"
                  :data-source="items"
                  :pagination="false"
                  :rowKey="(record) => recordIndex(record)"
                >
                  <template #hsCode="{record}">
                    <div class="editable-cell field-col">
                      <div class="editable-cell-input-wrapper">
                        <number-field
                          v-bind="fields.number"
                          :rules="fields.number.rules"
                          :field-value="items[recordIndex(record)]['hsCode']"
                          @update:fieldValue="inputChanged('hsCode', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'hsCode']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #productNumber="{ record }">
                    <div class="editable-cell field-col">
                      <div class="editable-cell-input-wrapper">
                        <text-field
                          v-bind="fields.text"
                          :field-value="items[recordIndex(record)]['productNumber']"
                          @update:fieldValue="inputChanged('productNumber', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'productNumber']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #productCategoryId="{ record }">
                    <div class="editable-cell field-col field-col-select-large">
                      <div class="editable-cell-input-wrapper">
                        <select-field
                          v-bind="fields.select"
                          :options="extractActiveListOptions(productCategories, items[recordIndex(record)],
                            'productCategoryId')"
                          :field-value="items[recordIndex(record)]['productCategoryId']"
                          @update:fieldValue="inputChanged('productCategoryId', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'productCategoryId']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #countryOfOrigin="{ record }">
                    <div class="editable-cell field-col field-col-select">
                      <div class="editable-cell-input-wrapper">
                        <select-field
                          v-bind="fields.select"
                          :enableSearch="true"
                          :dropDownAlign="true"
                          :options="countries"
                          :field-value="items[recordIndex(record)]['countryOfOrigin']"
                          @update:fieldValue="inputChanged('countryOfOrigin', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'countryOfOrigin']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #itemType="{ record }">
                    <div class="editable-cell field-col field-col-select">
                      <div class="editable-cell-input-wrapper">
                        <select-field
                          v-bind="fields.select"
                          :options="productTypeOptions"
                          :field-value="items[recordIndex(record)]['itemType']"
                          @update:fieldValue="inputChanged('itemType', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'itemType']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #descriptionOfGoods="{ record }">
                    <div class="editable-cell field-col text-field-col">
                      <div class="editable-cell-input-wrapper">
                        <text-area
                          v-bind="fields.textArea"
                          :field-value="items[recordIndex(record)]['descriptionOfGoods']"
                          @update:fieldValue="inputChanged('descriptionOfGoods', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'descriptionOfGoods']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #comment="{ record }">
                    <div class="editable-cell field-col text-field-col">
                      <div class="editable-cell-input-wrapper">
                        <text-area
                          :field-value="items[recordIndex(record)]['comment']"
                          @update:fieldValue="inputChanged('comment', recordIndex(record), $event)"
                          :fieldKey="[recordIndex(record), 'comment']"
                        />
                      </div>
                    </div>
                  </template>
                  <template #dataSheet="{ record }">
                    <div class="editable-cell upload-field-col">
                      <div class="editable-cell-input-wrapper">
                        <file-uploader
                          v-bind="fields.fileUploader"
                          :field-value="items[recordIndex(record)]['dataSheet']"
                          :files-value="items[recordIndex(record)]['dataSheet']"
                          :fieldKey="[recordIndex(record), 'dataSheet']"
                          @update:filesValue="inputChanged('dataSheet', recordIndex(record), $event)"
                        />
                      </div>
                    </div>
                  </template>

                  <template #actions="{ record }">
                    <template v-if="items.length > 1">
                      <remove-item-button :record="record" @removeItem="handleRemove(record)" />
                    </template>
                  </template>
                </a-table>
              </a-form>
            </table-wrapper>
          </table-style-wrapper>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </sdCards>
    <div class="sDash_form-action">
      <sdButton class="btn-signin ml-10" type="default"
        @click="previousStep" html-type="button">
        {{$t('actions.previous')}}
      </sdButton>
      <span class="float-button-right display-inline">
        <sdButton class="btn-signin mr-10" type="default"
          @click="handleAdd" html-type="button">
            {{$t('actions.add')}}
        </sdButton>
        <a-button
          class="btn-signin mr-10"
          size="small"
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="submitForm"
        >
          <span>{{lastStep ? $t('actions.submit') : $t('actions.next')}}</span>
        </a-button>
      </span>
    </div>
  </Main>
</template>

<script>
import {
  computed, defineComponent, ref
} from 'vue';
import {useI18n} from 'vue-i18n';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import NumberField from '@/components/shared/fields/Number';
import TextArea from '@/components/shared/fields/TextArea';
import TextField from '@/components/shared/fields/Text';
import FileUploader from '@/components/shared/fields/FileUploader';
import SelectField from '@/components/shared/fields/Select';
import _ from 'lodash';
import RemoveItemButton from '@/components/registration/RemoveItemButton';
import {
  requiredNumber, required, rangeNumerical
} from '@/helpers/validationRules';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import {extractActiveListOptions} from '@/helpers/dynamicListsOptions';
import {Main} from '@/components/shared/styledComponents/Main';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  components: {
    VerticalFormStyleWrap,
    FormValidationWrap,
    TableStyleWrapper,
    TableWrapper,
    NumberField,
    TextArea,
    TextField,
    SelectField,
    FileUploader,
    RemoveItemButton,
    Main
  },
  props: {
    data: VueTypes.object,
    loading: VueTypes.bool.def(false),
    lastStep: VueTypes.bool.def(false)
  },
  emits: ['submitForm', 'previousStep'],
  setup(props, context) {
    const {t} = useI18n();
    const {getters} = useStore();
    const countries = computed(() => getters.countries);

    const items = ref([]);
    const removedItems = ref([]);
    const handleAdd = (e) => {
      e?.preventDefault();
      items.value.push({
        hsCode: '',
        comment: '',
        productNumber: '',
        productCategoryId: '',
        descriptionOfGoods: '',
        countryOfOrigin: '',
        itemType: '',
        dataSheet: undefined,
        linkedToShipment: false
      });
    };

    const handleRemove = (item) => {
      const index = recordIndex(item);
      if (index > -1) items.value.splice(index, 1);
      if (item.id) {
        removedItems.value.push({id: item.id, _destroy: true});
      }
    };

    const columns = computed(() => [
      {
        title: t('models.productItem.attributes.hsCode'),
        dataIndex: 'hsCode',
        slots: {customRender: 'hsCode'}
      },
      {
        title: t('models.productItem.attributes.productNumber'),
        dataIndex: 'productNumber',
        slots: {customRender: 'productNumber'}
      },
      {
        title: t('models.productItem.attributes.productCategory'),
        dataIndex: 'productCategoryId',
        slots: {customRender: 'productCategoryId'}
      },
      {
        title: t('models.productItem.attributes.countryOfOrigin'),
        dataIndex: 'countryOfOrigin',
        slots: {customRender: 'countryOfOrigin'},
        width: '10%'
      },
      {
        title: t('models.productItem.attributes.itemType'),
        dataIndex: 'itemType',
        slots: {customRender: 'itemType'},
        width: '10%'
      },
      {
        title: t('models.productItem.attributes.descriptionOfGoods'),
        dataIndex: 'descriptionOfGoods',
        slots: {customRender: 'descriptionOfGoods'}
      },
      {
        title: t('models.productItem.attributes.comment'),
        dataIndex: 'comment',
        slots: {customRender: 'comment'}
      },
      {
        title: t('models.productItem.attributes.dataSheet'),
        dataIndex: 'itemType',
        slots: {customRender: 'dataSheet'}
      },
      {
        slots: {customRender: 'actions'},
        width: 100
      }
    ]);

    const formRef = ref();

    const fields = {
      number: {
        min: 0,
        rules: [requiredNumber, rangeNumerical(6, 14)],
        size: 'small'
      },
      select: {
        rules: [required],
        size: 'small'
      },
      textArea: {
        rules: [required]
      },
      text: {
        rules: [required],
        size: 'small'
      },
      fileUploader: {
        multiple: false,
        iconButton: true
      }
    };

    const productTypeOptions = [
      {label: 'Software', value: 'SW'},
      {label: 'Hardware', value: 'HW'}
    ];

    const inputChanged = (key, index, value) => items.value[index][key] = value;
    const productCategories = computed(() => getters.productCategories);

    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(() => {
          context.emit('deleteProducts', removedItems.value);
          context.emit('submitForm', items.value);
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const previousStep = (e) => {
      e.preventDefault();
      context.emit('previousStep', items.value);
    };

    const initiateTable = () => {
      _.isEmpty(props.data) ? handleAdd() : _.forEach(props.data, (row) => fillRow(row));
    };

    const fillRow = (data) => {
      items.value.push({
        id: data.id,
        hsCode: Number(data.hsCode),
        comment: data.comment,
        productNumber: data.productNumber,
        productCategoryId: data.productCategoryId,
        descriptionOfGoods: data.descriptionOfGoods,
        countryOfOrigin: data.countryOfOrigin,
        itemType: data.itemType,
        dataSheet: data.dataSheet,
        linkedToShipment: data.linkedToShipment
      });
    };

    const recordIndex = (record) => _.indexOf(items.value, record);

    initiateTable();

    return {
      formRef,
      columns,
      fields,
      items,
      countries,
      productTypeOptions,
      productCategories,
      extractActiveListOptions,
      inputChanged,
      submitForm,
      handleAdd,
      handleRemove,
      previousStep,
      recordIndex
    };
  }
});
</script>

<style lang="scss" scoped>
.sDash_form-action button {
  border-radius: 4px;
  padding: 6.4px 29.2px;
  height: 44px;
}

@media only screen and (max-width: 500px){
  .sDash_form-action button,
  .sDash_form-action span {
    width: 100% !important;
  }
}
</style>
